




















































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from '../../mixins/input-setups'
import Placeholders from '@/mixins/placeholders/placeholders'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import CurrentModule from '@/components/Modules/components/CurrentModule.vue'
import { ModuleTypesEnum } from '@/includes/logic/Modules/types/types'
import TempCurrentModuleMixin from '@/includes/logic/Modules/mixins/TempCurrentModuleMixin'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    CurrentModule,
    CenteredColumnLayout,
    PageTitle
  },
  data() {
    return {
      MediaEditorMode,
    }
  }
})
export default class ModerationCommands extends Mixins(UseFields, InputSetups, Placeholders, TariffsTagsHelper, TempCurrentModuleMixin) {
  created() {
    this.setCurrentModule(ModuleTypesEnum.ModerateCommandHandlerModule)
  }
}
